@font-face {
  font-family: "Avenir Medium";
  src: url(/public/fonts/Avenir_Medium.ttf);
}
@font-face {
  font-family: "Avenir Book";
  src: url(/public/fonts/Avenir_Book.ttf);
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: "Gotham", sans-serif !important;
}
a {
  text-decoration: none !important;
}
.homeMain {
  background-image: url(../../public/imgs/home_banner.png);
  background-size: cover;
  header {
    padding: 20px 0px;
    .logo {
      width: 150px;
    }
    i {
      color: white;
    }
    span {
      color: white;
      font-family: "Gotham", sans-serif;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .searchMain {
    padding: 9% 10px 11%;
    h1 {
      color: white;
      font-family: "Gotham", sans-serif;
      font-size: 76px;
      @media screen and (max-width: 767px) {
        font-size: 50px;
      }
    }
    p {
      color: white;
      font-family: "Gotham", sans-serif;
      font-weight: 400;
      font-size: 30px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    .search {
      background-color: white;
      padding: 10px;
      button {
        margin-right: 10px;
        color: white;
        padding: 10px 20px 12px;
        border: none;
        background: rgb(79, 66, 186);
        background: transparent
          linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0%
          no-repeat padding-box;
        border-radius: 10px;
        font-family: "Gotham", sans-serif;
        font-weight: bold;
        font-size: 20px;
        @media screen and (max-width: 767px) {
          margin-top: 10px;
        }
        @media screen and (max-width: 575px) {
          font-size: 16px;
        }
        img {
          margin-left: 5px;
          width: 30px;
          @media screen and (max-width: 575px) {
            width: 20px;
          }
        }
      }
      .customInput {
        padding: 10px;
        width: 47%;
        border-radius: 5px;
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        label {
          font-family: "Gotham Black", sans-serif;
          color: black;
          font-weight: 500;
          margin-bottom: 0px;
          text-transform: uppercase;
          font-size: 18px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
        input {
          background: transparent;
          border: none;
          font-family: "Gotham", sans-serif;
          font-weight: 400;
          font-size: 20px;
          color: #7d7d7d;
          width: 100%;
          @media screen and (max-width: 767px) {
            font-size: 16px;
          }

          &::placeholder {
            font-family: "Gotham", sans-serif;
            font-weight: 400;
            font-size: 20px;
            color: #7d7d7d;
            @media screen and (max-width: 767px) {
              font-size: 16px;
            }
          }
          &:focus-visible {
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
      }
      .customInput.focused {
        background-color: #e0e0e082;
      }
      .seperator {
        width: 4%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        .line {
          width: 2px;
          background-color: #7d7d7d;
          height: 50px;
          @media screen and (max-width: 575px) {
            width: 100px;
            height: 2px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.storageMain {
  background-image: url(../../public/imgs/storage_banner.png);
  background-size: cover;
  .searchMain {
    padding: 12% 10px 15%;
    h1 {
      font-size: 50px;
      text-align: center;
      max-width: 950px;
      margin: 0 auto;
      font-weight: 600;
      @media screen and (max-width: 575px) {
        font-size: 25px;
      }
    }
    p {
      font-size: 28px;
      max-width: 750px;
      margin: 0 auto;
      text-align: center;
      padding-top: 20px;
      @media screen and (max-width: 575px) {
        font-size: 18px;
      }
    }
  }
}
.steps.homeMain {
  background-image: url(../../public/imgs/steps.png);
  background-size: cover;
}
.justAMove {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    color: #100094;
    font-size: 40px;
    text-align: center;
    @media screen and (max-width: 575px) {
      font-size: 30px;
    }
  }
  p {
    font-family: "Gotham", sans-serif;
    font-size: 25px;
    text-align: center;
    @media screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
}
.moveCard {
  border: 1px solid #70707065;
  padding: 30px 20px;
  height: 100%;
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    font-size: 35px;
    color: #100094;
    margin-top: 10px;
    @media screen and (max-width: 575px) {
      font-size: 25px;
    }
  }
  p {
    font-family: "Avenir LT Std", sans-serif;
    font-size: 25px;
    color: black;
    margin-bottom: 0px;
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }
}
.moveCard.active {
  background: transparent
    linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0%
    no-repeat padding-box;
  padding: 30px 20px;
  h1 {
    color: white;
  }
  p {
    color: white;
  }
}
.thirdSection {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: #100094;
    text-align: center;
  }
  p {
    color: #4d4d4d;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    text-align: center;
  }
  .companyCard {
    @media screen and (min-width: 992px) {
      min-height: 450px;
    }
    cursor: pointer;
    h1 {
      font-family: "Gotham", sans-serif;
      font-weight: 600;
      font-size: 20px;
      color: black;
      text-align: center;
    }
    p {
      color: #666666;
      font-family: "Gotham", sans-serif;
      font-size: 16px;
      text-align: left;
    }
    .imgDiv {
      width: 140px;
      background: white;
      padding: 10px 20px;
      margin: 0 auto 20px;
      text-align: center;
    }
  }
  .companyCard.active {
    .imgDiv {
      background: transparent
        linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0%
        no-repeat padding-box;
    }
  }
}
.fourthSection {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    font-size: 35px;
    color: #100094;
    text-align: left;
    @media screen and (max-width: 575px) {
      font-size: 25px;
    }
  }
  h2 {
    color: #595959;
    font-family: "Gotham", sans-serif;
    font-size: 16px;
    text-align: left;
  }
  p {
    font-family: "Gotham", sans-serif;
    font-size: 17px;
    text-align: left;
    color: #000000;
  }
  ul {
    padding-left: 20px;
    li {
      font-family: "Gotham", sans-serif;
      font-weight: bold;
      font-size: 17px;
      text-align: left;
      color: #000000;
      line-height: 30px;
    }
  }
  .expImg {
    margin-top: -60px;
    @media screen and (max-width: 767px) {
      margin: 20px 0px;
    }
  }
}
.arrowBtn {
  margin-right: 10px;
  color: white;
  padding: 10px 20px 12px;
  border: none;
  background: rgb(79, 66, 186);
  background: transparent
    linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  font-family: "Gotham", sans-serif;
  font-weight: bold;
  font-size: 18px;
  img {
    width: 20px;
  }
}
footer {
  background: rgb(84, 164, 217);
  background: linear-gradient(
    150deg,
    rgba(84, 164, 217, 1) 0%,
    rgba(60, 49, 130, 1) 100%
  );
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: white;
  }
  i {
    color: white;
    font-size: 12px;
  }
  span {
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: white;
  }
  .input-group {
    background-color: white;
    border-radius: 0px;
    padding: 5px 0px;
    width: 80%;
    margin-top: 10px;
  }
  input {
    border: 0px !important;
    color: #445962;
    font-size: 16px;
    &::placeholder {
      color: #445962;
      font-size: 16px;
    }
  }
  .input-group-text {
    background: transparent;
    border: none !important;
    i {
      background-color: #100094;
      padding: 5px;
      border-radius: 50%;
    }
  }
  .getQuote {
    color: #54a3d8;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    font-size: 16px;
    border-radius: 12px;
    background-color: white;
    padding: 5px 10px;
    border: none;
  }
  h3 {
    font-family: "Gotham", sans-serif;
    color: white;
    font-weight: 300;
    font-size: 20px;
    margin-top: 50px;
  }
  .followUs {
    i {
      color: white;
      font-size: 22px;
    }
  }
  h4 {
    color: white;
    font-size: 25px;
    font-family: "Gotham", sans-serif;
    margin-top: 10px;
  }
  h5 {
    color: white;
    font-size: 18px;
    font-family: "Gotham", sans-serif;
    margin-top: 10px;
    i {
      font-size: 20px;
    }
  }
  p {
    color: white;
    font-size: 16px;
    font-family: "Gotham", sans-serif;
    margin-bottom: 0px;
    a {
      color: white;
      text-decoration: none;
    }
  }
  h4.copyRight {
    font-family: "Gotham Light", sans-serif;
    color: white;
    font-size: 12px;
    font-weight: 300;
  }
}
.sidebar {
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin-right: -1px;
  text-align: center;
  z-index: 9;
  .sidebar-inner {
    padding: 20px;
    background: rgb(84, 164, 217);
    background: linear-gradient(
      150deg,
      rgba(84, 164, 217, 1) 0%,
      rgba(60, 49, 130, 1) 100%
    );
  }
  .logo {
    width: 150px;
  }
  .getQuote {
    color: #54a3d8;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    font-size: 16px;
    border-radius: 5px;
    background-color: white;
    padding: 5px 26px;
    border: none;
    margin-bottom: 10px;
  }
  .bookSurvey {
    color: white;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    font-size: 13px;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px 10px;
    border: 1px solid white;
  }
  .phone {
    color: white;
    font-family: "Gotham", sans-serif;
    font-weight: bold;
  }
  hr {
    border-color: white;
    border-width: 2px;
  }
  .menu {
    text-align: left;
    margin-top: 50px;
    ul {
      li {
        margin-bottom: 10px;
        a {
          color: white;
          font-family: "Gotham", sans-serif;
          font-weight: 500;
          height: auto;
          font-size: 20px;
          &:hover {
            background-color: transparent;
          }
          .expand-icon {
            span {
              width: 10px;
              height: 10px;
              position: relative;
              top: -3px;
            }
          }
          a {
            text-decoration: none;
          }
        }
      }
    }
    .sub-menu-content {
      background-color: transparent;
      ul {
        li {
          margin-bottom: 0px;
          a {
            color: white;
            font-family: "Gotham", sans-serif;
            font-weight: 400;
            height: auto;
            font-size: 14px;
            margin-bottom: 0px;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
.goodHands {
  h1 {
    font-size: 43px;
    font-family: "Gotham Black", sans-serif;
    text-align: center;
  }
  P {
    font-family: "Avenir Medium", sans-serif;
    text-align: center;
    color: black;
    font-size: 24px;
    max-width: 720px;
    margin: 20px auto;
  }
}
.gradientBtn {
  margin-right: 10px;
  color: white;
  padding: 7px 25px 9px;
  border: none;
  background: rgb(79, 66, 186);
  background: transparent
    linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 4px;
  font-family: "Gotham Medium", sans-serif;
  font-size: 18px;
}
.hollowBtn {
  margin-right: 10px;
  color: #3927b1;
  padding: 7px 25px 9px;
  border: 1px solid #3927b1;
  background: transparent;
  border-radius: 4px;
  font-family: "Gotham Medium", sans-serif;
  font-size: 18px;
}
.storageNeeds {
  background-color: #3c3182;
  padding: 30px 0px;
  h1 {
    font-family: "Gotham Medium", sans-serif;
    font-size: 50px;
    color: black;
    max-width: 400px;
    @media screen and (max-width: 575px) {
      font-size: 35px;
    }
  }
  p {
    font-family: "Segoe UI", sans-serif;
    font-size: 24px;
    color: black;
    max-width: 450px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }
}
.storeMain {
  padding: 0px 0px;
  h1 {
    font-family: "Gotham Black", sans-serif;
    font-size: 42px;
    max-width: 420px;
  }
  .content {
    img {
      width: 24px;
      margin-top: -10px;
    }
    h2 {
      font-family: "Gotham", sans-serif;
      font-size: 26px;
      font-weight: bold;
    }
    p {
      font-family: "Gotham", sans-serif;
      font-size: 18px;
    }
  }
}
.bestMoving {
  h1 {
    font-family: "Gotham", sans-serif;
    font-size: 34px;
    font-weight: bold;
  }
  p {
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    max-width: 730px;
    margin-top: 20px;
  }
  .curvedDiv {
    height: 160px;
    position: relative;
    margin-top: -50px;
    @media screen and (max-width: 767px) {
      margin-top: 0px;
    }
    .curved {
      clip-path: polygon(100% 1%, 0% 100%, 100% 100%);
      height: 160px;
      background: #404692;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 160px;
    }
  }
}
.getStartedBtn {
  position: relative;
  border: none;
  background: rgb(84, 164, 217);
  background: linear-gradient(
    150deg,
    rgba(84, 164, 217, 1) 0%,
    rgba(60, 49, 130, 1) 100%
  );
  padding: 10px;
  font-family: "Gotham", sans-serif;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  width: 150px;
  margin-top: 70px;
}
.commercialMoverMain {
  background-image: url(../../public/imgs/movers_banner.png);
  background-size: cover;
  .searchMain {
    h1 {
      font-size: 45px;
    }
    p {
      font-size: 26px;
    }
  }
}
.takeBusiness {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 42px;
    text-align: center;
  }
  p {
    font-family: "Avenir Medium";
    font-size: 34px;
    text-align: center;
    max-width: 985px;
    margin: 0 auto;
  }
}
.commercialTrust {
  background-color: white;
  padding: 60px 0px;
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 42px;
    max-width: 430px;
  }
  P {
    font-family: "Avenir Book";
    font-size: 20px;
  }
}
.holder {
  max-width: 700px;
  h1 {
    font-size: 73px !important;
  }
}
.lagosMain {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 42px;
    color: #54a3d8;
    max-width: 607px;
    @media screen and (max-width: 767px) {
      font-size: 34px;
    }
  }
  P {
    font-family: "Avenir Book";
    font-size: 20px;
  }
}
.keyPointsMain {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 34px;
    color: #54a3d8;
    text-align: center;
  }
  h2 {
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  h3 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  p {
    font-family: "Avenir Book";
    font-size: 16px;
  }
}
.justaMove {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 34px;
    text-align: center;
  }
  h2 {
    font-family: "Gotham", sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  }
  h3 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 17px;
  }
  p {
    font-family: "Avenir Book";
    font-size: 16px;
  }
  h5 {
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    font-size: 24px;
  }
  h4 {
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    font-size: 27px;
  }
  .step {
    img {
      width: 100px;
      height: 100px;
    }
    h3 {
      font-family: "Gotham", sans-serif;
      font-weight: 600;
      font-size: 22px;
    }

    p {
      font-family: "Avenir Book";
      font-size: 16px;
    }
  }
}
.getQuote {
  color: #54a3d8;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  font-size: 16px;
  border-radius: 12px;
  background-color: white;
  padding: 5px 20px;
  border: none;
}
.contactUs {
  background: #5a96df;
}
.convoBox {
  background-color: white;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 50px;
  margin-top: -50px;
  h1 {
    text-align: center;
    font-family: "Gotham Black", sans-serif;
    font-size: 45px;
    color: #5a96df;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      font-size: 34px;
    }
  }
  p {
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
  }
}
.getInTouch {
  background-color: #f7f7f7;
  .main {
    h1 {
      font-family: "Gotham", sans-serif;
      font-size: 40px;
      position: relative;
      display: inline;
      &::after {
        content: "";
        width: 80%;
        height: 4px;
        background: #5a96df;
        position: absolute;
        left: 0;
        bottom: -4px;
      }
    }
    p {
      font-family: "Gotham", sans-serif;
      font-weight: 500;
      font-size: 20px;
      margin: 30px 0px;
    }
  }
  .three {
    img {
      width: 85px;
    }
    .schedule {
      img {
        width: 168px;
      }
    }
    .questions {
      img {
        width: 125px;
      }
    }
    h2 {
      font-family: "Gotham", sans-serif;
      font-size: 24px;
      position: relative;
      margin: 20px 0px;
      &::after {
        content: "";
        width: 70%;
        height: 4px;
        background: #5a96df;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    p {
      font-family: "Gotham", sans-serif;
      font-size: 18px;
      text-align: left;
    }
    button {
      background-color: #5a96df;
      border: none;
      border-radius: 4px;
      color: white;
      padding: 8px;
      font-family: "Gotham", sans-serif;
      font-size: 18px;
      width: 180px;
      i {
        color: white;
        font-size: 15px;
        margin-left: 5px;
      }
    }
  }
}
.stepperMain {
  position: relative;
  z-index: 8;
  background-color: white;
  padding: 20px;
  .stepper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
    .connector {
      flex-basis: 19%;
      background: #f2f2f2;
      height: 10px;
    }
    .connector.active {
      background: transparent
        linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
    }
    .step {
      width: 2em;
      border: none;
      cursor: pointer;
      height: 2em;
      margin: 0px;
      display: flex;
      padding: 0px;
      font-size: 1em;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      background-color: #f2f2f2;
      color: #000000 !important;
      font-family: "Gotham", sans-serif;
      font-size: 24px;
    }
    .step.active {
      background: transparent
        linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
      color: white !important;
    }
    .step.completed {
      background: transparent
        linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
      color: white !important;
    }
  }
}
.content {
  h1 {
    font-family: "Gotham", sans-serif;
    font-weight: 600;
    font-size: 34px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
  }
  p {
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
  .seperator {
    background-color: #6666664f;
    height: 3px;
    margin: 20px 0px;
  }
  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid #6666664f;
    padding: 10px;
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    height: 50px;
    color: #666666;
    &::placeholder {
      font-family: "Gotham", sans-serif;
      font-size: 18px;
      height: 50px;
      color: #666666;
    }
  }
  textarea {
    height: auto;
  }
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }
  .form-check-label {
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    padding-left: 10px;
  }
  .form-check-input:checked {
    background-color: #5133b7;
    border-color: #5133b7;
  }
}
.checkboxContainer {
  max-width: 230px;
  margin: 0 auto;
}
.react-calendar {
  margin: 0 auto;
}
.react-calendar__navigation {
  background: transparent
    linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
  button {
    color: white !important;
  }
}
.react-calendar__tile--active {
  background: transparent
    linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
}
.boldLabels {
  label {
    font-weight: 600;
  }
}
.selectRange {
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.form-check-input[type="radio"] {
  width: 10px;
  height: 10px;
}
