@font-face {
  font-family: "Avenir Medium";
  src: url(/public/fonts/Avenir_Medium.ttf);
}

@font-face {
  font-family: "Avenir Book";
  src: url(/public/fonts/Avenir_Book.ttf);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: 'Roboto' !important;
}

a {
  text-decoration: none !important;
}

.homeMain {
  /* background-image: url(../../public/imgs/home_banner.png); */
  background-size: cover;
}

.localMovers {
  /* background-image: url(../../public/imgs/bg_localMovers.png); */
  background-size: cover;
}

.blogs {
  /* background-image: url(../../public/imgs/cover.jpg); */
  background-size: cover;
}

.reviews {
  /* background-image: url(../../public/imgs/reviews.png); */
  width: 100%;
}

.homeMain header {
  padding: 20px 0px;
}

.homeMain header .logo {
  width: 150px;
  height: 50px;
  object-fit: cover;
}

.homeMain header i {
  color: white;
}

.homeMain header span {
  color: white;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
}

.homeMain .searchMain {
  padding: 9% 10px 11%;
}

.homeMain .searchMain h1 {
  color: white;
  font-family: 'Roboto';
  font-size: 76px;
}

@media screen and (max-width: 767px) {
  .homeMain .searchMain h1 {
    font-size: 50px;
  }
}

.homeMain .searchMain p {
  color: white;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .homeMain .searchMain p {
    font-size: 20px;
  }
}

.homeMain .searchMain .search {
  background-color: white;
  padding: 10px;
}

.homeMain .searchMain .search button {
  margin-right: 10px;
  color: white;
  padding: 10px 20px 12px;
  border: none;
  background: #4f42ba;
  background: transparent linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .homeMain .searchMain .search button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .homeMain .searchMain .search button {
    font-size: 16px;
  }
}

.homeMain .searchMain .search button img {
  margin-left: 5px;
  width: 30px;
}

@media screen and (max-width: 575px) {
  .homeMain .searchMain .search button img {
    width: 20px;
  }
}

.homeMain .searchMain .search .customInput {
  padding: 10px;
  width: 47%;
  border-radius: 5px;
}

@media screen and (max-width: 575px) {
  .homeMain .searchMain .search .customInput {
    width: 100%;
  }
}

.homeMain .searchMain .search .customInput label {
  font-family: "Gotham Black", sans-serif;
  color: black;
  font-weight: 500;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .homeMain .searchMain .search .customInput label {
    font-size: 14px;
  }
}

.homeMain .searchMain .search .customInput input {
  background: transparent;
  border: none;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  color: #7d7d7d;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .homeMain .searchMain .search .customInput input {
    font-size: 16px;
  }
}

.homeMain .searchMain .search .customInput input::-webkit-input-placeholder {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  color: #7d7d7d;
}

.homeMain .searchMain .search .customInput input:-ms-input-placeholder {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  color: #7d7d7d;
}

.homeMain .searchMain .search .customInput input::-ms-input-placeholder {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  color: #7d7d7d;
}

.homeMain .searchMain .search .customInput input::placeholder {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  color: #7d7d7d;
}

@media screen and (max-width: 767px) {
  .homeMain .searchMain .search .customInput input::-webkit-input-placeholder {
    font-size: 16px;
  }
  .homeMain .searchMain .search .customInput input:-ms-input-placeholder {
    font-size: 16px;
  }
  .homeMain .searchMain .search .customInput input::-ms-input-placeholder {
    font-size: 16px;
  }
  .homeMain .searchMain .search .customInput input::placeholder {
    font-size: 16px;
  }
}

.homeMain .searchMain .search .customInput input:focus-visible {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.homeMain .searchMain .search .customInput.focused {
  background-color: #e0e0e082;
}

.homeMain .searchMain .search .seperator {
  width: 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-width: 575px) {
  .homeMain .searchMain .search .seperator {
    width: 100%;
  }
}

.homeMain .searchMain .search .seperator .line {
  width: 2px;
  background-color: #7d7d7d;
  height: 50px;
}

@media screen and (max-width: 575px) {
  .homeMain .searchMain .search .seperator {
    width: 100px;
    height: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 578px) {
  .line {
    display: none;
  }
}

.storageMain {
  /* background-image: url(../../public/imgs/storage_banner.png); */
  background-size: cover;
}

.storageMain .searchMain {
  padding: 12% 10px 15%;
}

.storageMain .searchMain h1 {
  font-size: 50px;
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
  font-weight: 600;
}

@media screen and (max-width: 575px) {
  .storageMain .searchMain h1 {
    font-size: 25px;
  }
}

.storageMain .searchMain p {
  font-size: 28px;
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
}

@media screen and (max-width: 575px) {
  .storageMain .searchMain p {
    font-size: 18px;
  }
}

.steps.homeMain {
  /* background-image: url(../../public/imgs/steps.png); */
  background-size: cover;
}

.justAMove h1 {
  font-family: 'Roboto';
  font-weight: bold;
  color: #100094;
  font-size: 40px;
  text-align: center;
}

@media screen and (max-width: 575px) {
  .justAMove h1 {
    font-size: 30px;
  }
}

.justAMove p {
  font-family: 'Roboto';
  font-size: 25px;
  text-align: center;
}

@media screen and (max-width: 575px) {
  .justAMove p {
    font-size: 20px;
  }
}

.moveCard {
  border: 1px solid #70707065;
  padding: 30px 20px;
  height: 100%;
}

.moveCard h1 {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 35px;
  color: #100094;
  margin-top: 10px;
}

@media screen and (max-width: 575px) {
  .moveCard h1 {
    font-size: 25px;
  }
}

.moveCard p {
  font-family: "Avenir LT Std", sans-serif;
  font-size: 25px;
  color: black;
  margin-bottom: 0px;
}

@media screen and (max-width: 575px) {
  .moveCard p {
    font-size: 18px;
  }
}

.moveCard.active {
  background: transparent linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0% no-repeat padding-box;
  padding: 30px 20px;
}

.moveCard.active h1 {
  color: white;
}

.moveCard.active p {
  color: white;
}

.thirdSection h1 {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 22px;
  color: #100094;
  text-align: center;
}

.thirdSection p {
  color: #4d4d4d;
  font-family: 'Roboto';
  font-size: 22px;
  text-align: center;
}

.thirdSection .companyCard {
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .thirdSection .companyCard {
    min-height: 450px;
  }
}

.thirdSection .companyCard h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 20px;
  color: black;
  text-align: center;
}

.thirdSection .companyCard p {
  color: #666666;
  font-family: 'Roboto';
  font-size: 16px;
  text-align: left;
}

.thirdSection .companyCard .imgDiv {
  width: 140px;
  background: white;
  padding: 10px 20px;
  margin: 0 auto 20px;
  text-align: center;
}

.thirdSection .companyCard.active .imgDiv {
  background: transparent linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0% no-repeat padding-box;
}

.fourthSection h1 {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 35px;
  color: #100094;
  text-align: left;
}

@media screen and (max-width: 575px) {
  .fourthSection h1 {
    font-size: 25px;
  }
}

.fourthSection h2 {
  color: #595959;
  font-family: 'Roboto';
  font-size: 16px;
  text-align: left;
}

.fourthSection p {
  font-family: 'Roboto';
  font-size: 17px;
  text-align: left;
  color: #000000;
}

.fourthSection ul {
  padding-left: 20px;
}

.fourthSection ul li {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 17px;
  text-align: left;
  color: #000000;
  line-height: 30px;
}

.fourthSection .expImg {
  margin-top: -60px;
}

@media screen and (max-width: 767px) {
  .fourthSection .expImg {
    margin: 20px 0px;
  }
}

.arrowBtn {
  margin-right: 10px;
  color: white;
  padding: 10px 20px 12px;
  border: none;
  background: #4f42ba;
  background: transparent linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 18px;
}

.arrowBtn img {
  width: 20px;
}

footer {
  background: #54a4d9;
  background: linear-gradient(150deg, #54a4d9 0%, #3c3182 100%);
}

footer h1 {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 30px;
  color: white;
}

footer i {
  color: white;
  font-size: 12px;
}

footer span {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  color: white;
}

footer .input-group {
  background-color: white;
  border-radius: 0px;
  padding: 5px 0px;
  width: 80%;
  margin-top: 10px;
}

footer input {
  border: 0px !important;
  color: #445962;
  font-size: 16px;
}

footer input::-webkit-input-placeholder {
  color: #445962;
  font-size: 16px;
}

footer input:-ms-input-placeholder {
  color: #445962;
  font-size: 16px;
}

footer input::-ms-input-placeholder {
  color: #445962;
  font-size: 16px;
}

footer input::placeholder {
  color: #445962;
  font-size: 16px;
}

footer .input-group-text {
  background: transparent;
  border: none !important;
}

footer .input-group-text i {
  background-color: #54a3d8;
  padding: 5px;
  border-radius: 50%;
}

footer .getQuote {
  color: #54a3d8;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  border-radius: 12px;
  background-color: white;
  padding: 5px 10px;
  border: none;
}

footer h3 {
  font-family: 'Roboto';
  color: white;
  font-weight: 300;
  font-size: 20px;
  margin-top: 50px;
}

footer .followUs i {
  color: white;
  font-size: 22px;
}

footer h4 {
  color: white;
  font-size: 25px;
  font-family: 'Roboto';
  margin-top: 10px;
}

footer h5 {
  color: white;
  font-size: 18px;
  font-family: 'Roboto';
  margin-top: 10px;
}

footer h5 i {
  font-size: 20px;
}

footer p {
  color: white;
  font-size: 16px;
  font-family: 'Roboto';
  margin-bottom: 0px;
}

footer p a {
  color: white;
  text-decoration: none;
}

footer h4.copyRight {
  font-family: "Gotham Light", sans-serif;
  color: white;
  font-size: 12px;
  font-weight: 300;
}

.sidebar {
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin-right: -1px;
  text-align: center;
  z-index: 9;
}

.sidebar .sidebar-inner {
  padding: 20px;
  background: #54a4d9;
  background: linear-gradient(150deg, #54a4d9 0%, #3c3182 100%);
}

.sidebar .logo {
  width: 150px;
  height: 50px;
  object-fit: cover;
}

.sidebar .getQuote {
  color: #54a3d8;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  background-color: white;
  padding: 5px 26px;
  border: none;
  margin-bottom: 10px;
}

.sidebar .bookSurvey {
  color: white;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px 10px;
  border: 1px solid white;
}

.sidebar .phone {
  color: white;
  font-family: 'Roboto';
  font-weight: bold;
}

.sidebar hr {
  border-color: white;
  border-width: 2px;
}

.sidebar .menu {
  text-align: left;
  margin-top: 50px;
}

.sidebar .menu ul li {
  margin-bottom: 10px;
}

.sidebar .menu ul li a {
  color: white;
  font-family: 'Roboto';
  font-weight: 500;
  height: auto;
  font-size: 20px;
}

.sidebar .menu ul li a:hover {
  background-color: transparent;
}

.sidebar .menu ul li a .expand-icon span {
  width: 10px;
  height: 10px;
  position: relative;
  top: -3px;
}

.sidebar .menu ul li a a {
  text-decoration: none;
}

.sidebar .menu .sub-menu-content {
  background-color: transparent;
}

.sidebar .menu .sub-menu-content ul li {
  margin-bottom: 0px;
}

.sidebar .menu .sub-menu-content ul li a {
  color: white;
  font-family: 'Roboto';
  font-weight: 400;
  height: auto;
  font-size: 14px;
  margin-bottom: 0px;
}

.sidebar .menu .sub-menu-content ul li a:hover {
  background-color: transparent;
}

.goodHands h1 {
  font-size: 43px;
  font-family: "Gotham Black", sans-serif;
  text-align: center;
}

.goodHands P {
  font-family: "Avenir Medium", sans-serif;
  text-align: center;
  color: black;
  font-size: 24px;
  max-width: 720px;
  margin: 20px auto;
}

.gradientBtn {
  margin-right: 10px;
  color: white;
  padding: 7px 25px 9px;
  border: none;
  background: #4f42ba;
  background: transparent linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-family: "Gotham Medium", sans-serif;
  font-size: 18px;
}

.hollowBtn {
  margin-right: 10px;
  color: #3927b1;
  padding: 7px 25px 9px;
  border: 1px solid #3927b1;
  background: transparent;
  border-radius: 4px;
  font-family: "Gotham Medium", sans-serif;
  font-size: 18px;
}

.storageNeeds {
  background-color: #3c3182;
  padding: 20px 0px;
}

.storageNeeds h1 {
  font-family: "Gotham Medium", sans-serif;
  font-size: 50px;
  color: black;
  max-width: 400px;
}

@media screen and (max-width: 575px) {
  .storageNeeds h1 {
    font-size: 35px;
  }
}

.storageNeeds p {
  font-family: "Segoe UI", sans-serif;
  font-size: 24px;
  color: black;
  max-width: 450px;
}

@media screen and (max-width: 767px) {
  .storageNeeds p {
    max-width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .storageNeeds p {
    font-size: 18px;
  }
}

.storeMain {
  padding: 0px 0px;
}

.storeMain h1 {
  font-family: "Gotham Black", sans-serif;
  font-size: 42px;
  max-width: 420px;
}

.storeMain .content img {
  width: 24px;
  margin-top: -10px;
}

.storeMain .content h2 {
  font-family: 'Roboto';
  font-size: 26px;
  font-weight: bold;
}

.storeMain .content p {
  font-family: 'Roboto';
  font-size: 18px;
}

.bestMoving h1 {
  font-family: 'Roboto';
  font-size: 34px;
  font-weight: bold;
}

.bestMoving p {
  font-family: 'Roboto';
  font-size: 20px;
  max-width: 730px;
  margin-top: 20px;
}

.bestMoving .curvedDiv {
  height: 160px;
  position: relative;
  margin-top: -50px;
}

@media screen and (max-width: 767px) {
  .bestMoving .curvedDiv {
    margin-top: 0px;
  }
}

.bestMoving .curvedDiv .curved {
  -webkit-clip-path: polygon(100% 1%, 0% 100%, 100% 100%);
          clip-path: polygon(100% 1%, 0% 100%, 100% 100%);
  height: 160px;
  background: #404692;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 160px;
}

.getStartedBtn {
  position: relative;
  border: none;
  background: #54a4d9;
  background: linear-gradient(150deg, #54a4d9 0%, #3c3182 100%);
  padding: 10px;
  font-family: 'Roboto';
  color: white;
  font-weight: 500;
  border-radius: 10px;
  width: 150px;
  margin-top: 70px;
}

.commercialMoverMain {
  /* background-image: url(../../public/imgs/movers_banner.png); */
  background-size: cover;
}

.commercialMoverMain .searchMain h1 {
  font-size: 45px;
}

.commercialMoverMain .searchMain p {
  font-size: 26px;
}

.takeBusiness h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 42px;
  text-align: center;
}

.takeBusiness p {
  font-family: "Avenir Medium";
  font-size: 34px;
  text-align: center;
  max-width: 985px;
  margin: 0 auto;
}

.commercialTrust {
  background-color: white;
  padding: 60px 0px;
}

.commercialTrust h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 42px;
  max-width: 430px;
}

.commercialTrust P {
  font-family: "Avenir Book";
  font-size: 20px;
}

.holder {
  max-width: 700px;
}

.holder h1 {
  font-size: 73px !important;
}

.lagosMain h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 42px;
  color: #54a3d8;
  max-width: 607px;
}

@media screen and (max-width: 767px) {
  .lagosMain h1 {
    font-size: 34px;
  }
}

.lagosMain P {
  font-family: "Avenir Book";
  font-size: 20px;
}

.keyPointsMain h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 34px;
  color: #54a3d8;
  text-align: center;
}

.keyPointsMain h2 {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.keyPointsMain h3 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 18px;
}

.keyPointsMain p {
  font-family: "Avenir Book";
  font-size: 16px;
}

.justaMove h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 34px;
  text-align: center;
}

.justaMove h2 {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.justaMove h3 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 17px;
}

.justaMove p {
  font-family: "Avenir Book";
  font-size: 16px;
}

.justaMove h5 {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
}

.justaMove h4 {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 27px;
}

.justaMove .step img {
  width: 100px;
  height: 100px;
}

.justaMove .step h3 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 22px;
}

.justaMove .step p {
  font-family: "Avenir Book";
  font-size: 16px;
}

.getQuote {
  color: #54a3d8;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  border-radius: 12px;
  background-color: white;
  padding: 5px 20px;
  border: none;
}

.contactUs {
  background: #5a96df;
}

.convoBox {
  background-color: white;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 50px;
  margin-top: -50px;
}

.convoBox h1 {
  text-align: center;
  font-family: "Gotham Black", sans-serif;
  font-size: 45px;
  color: #5a96df;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .convoBox h1 {
    font-size: 34px;
  }
}

.convoBox p {
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
}

.getInTouch {
  background-color: #f7f7f7;
}

.getInTouch .main h1 {
  font-family: 'Roboto';
  font-size: 40px;
  position: relative;
  display: inline;
}

.getInTouch .main h1::after {
  content: "";
  width: 80%;
  height: 4px;
  background: #5a96df;
  position: absolute;
  left: 0;
  bottom: -4px;
}

.getInTouch .main p {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  margin: 30px 0px;
}

.getInTouch .three img {
  width: 85px;
}

.getInTouch .three .schedule img {
  width: 168px;
}

.getInTouch .three .questions img {
  width: 125px;
}

.getInTouch .three h2 {
  font-family: 'Roboto';
  font-size: 24px;
  position: relative;
  margin: 20px 0px;
}

.getInTouch .three h2::after {
  content: "";
  width: 70%;
  height: 4px;
  background: #5a96df;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.getInTouch .three p {
  font-family: 'Roboto';
  font-size: 18px;
  text-align: left;
}

.getInTouch .three button {
  background-color: #5a96df;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 8px;
  font-family: 'Roboto';
  font-size: 18px;
  width: 180px;
}

.getInTouch .three button i {
  color: white;
  font-size: 15px;
  margin-left: 5px;
}

.stepperMain {
  position: relative;
  z-index: 8;
  background-color: white;
  padding: 20px;
}

.stepperMain .stepper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 30px 0px;
}

.stepperMain .stepper .connector {
  -ms-flex-preferred-size: 19%;
      flex-basis: 19%;
  background: #f2f2f2;
  height: 10px;
}

.stepperMain .stepper .connector.active {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#c46bd6), color-stop(72%, #3927b1), to(#3224afcc)) !important;
  background: transparent linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
}

.stepperMain .stepper .step {
  width: 2em;
  border: none;
  cursor: pointer;
  height: 2em;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  font-size: 1em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #f2f2f2;
  color: #000000 !important;
  font-family: 'Roboto';
  font-size: 24px;
}

.stepperMain .stepper .step.active {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#c46bd6), color-stop(72%, #3927b1), to(#3224afcc)) !important;
  background: transparent linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
  color: white !important;
}

.stepperMain .stepper .step.completed {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#c46bd6), color-stop(72%, #3927b1), to(#3224afcc)) !important;
  background: transparent linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
  color: white !important;
}

.content h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 34px;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
}

.content p {
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.content .seperator {
  background-color: #6666664f;
  height: 3px;
  margin: 20px 0px;
}

.content input,
.content select,
.content textarea {
  width: 100%;
  border: 1px solid #6666664f;
  padding: 10px;
  font-family: 'Roboto';
  font-size: 18px;
  height: 50px;
  color: #666666;
}

.content input::-webkit-input-placeholder,
.content select::-webkit-input-placeholder,
.content textarea::-webkit-input-placeholder {
  font-family: 'Roboto';
  font-size: 18px;
  height: 50px;
  color: #666666;
}

.content input:-ms-input-placeholder,
.content select:-ms-input-placeholder,
.content textarea:-ms-input-placeholder {
  font-family: 'Roboto';
  font-size: 18px;
  height: 50px;
  color: #666666;
}

.content input::-ms-input-placeholder,
.content select::-ms-input-placeholder,
.content textarea::-ms-input-placeholder {
  font-family: 'Roboto';
  font-size: 18px;
  height: 50px;
  color: #666666;
}

.content input::placeholder,
.content select::placeholder,
.content textarea::placeholder {
  font-family: 'Roboto';
  font-size: 18px;
  height: 50px;
  color: #666666;
}

.content textarea {
  height: auto;
}

.content input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.content .form-check-label {
  font-family: 'Roboto';
  font-size: 20px;
  padding-left: 10px;
}

.content .form-check-input:checked {
  background-color: #5133b7;
  border-color: #5133b7;
}

.checkboxContainer {
  max-width: 230px;
  margin: 0 auto;
}

.react-calendar {
  margin: 0 auto;
}

.react-calendar__navigation {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#c46bd6), color-stop(72%, #3927b1), to(#3224afcc)) !important;
  background: transparent linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
}

.react-calendar__navigation button {
  color: white !important;
}

.react-calendar__tile--active {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#c46bd6), color-stop(72%, #3927b1), to(#3224afcc)) !important;
  background: transparent linear-gradient(180deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) !important;
}

.boldLabels label {
  font-weight: 600;
}

.selectRange {
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.form-check-input[type="radio"] {
  width: 10px;
  height: 10px;
}

.error {
    color: red;
    float: left;
    font-size: 18px;
}

.gif img {
  margin-left: 10%;
  width: 90%;
}

.blog {
  /* display: inline-block; */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  height: 100%;
}

.blog img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}
.blog h3 {
  font-size: 20px;
}
.blog p {
  font-size: 15px;
}

@media screen and (min-width: 990px) {
  .blog {
    width: 32%;
    /* height: 620px; */
    margin: 5px;
  }
}

@media screen and (max-width: 989px) {
  .blog {
    width: 48%;
    /* height: 750px; */
    margin: 5px;
  }
}

@media screen and (max-width: 500px) {
  .blog {
    width: 98%;
    margin: 5px;
    /* height: 600px; */
  }
}

.tips h1 {
  font-family: 'Roboto';
  font-weight: 800;
  color: #100094;
  font-size: 50px;
  margin-bottom: 3%;
}

.tips .img1, .tips .img2 {
  width: 100%;
  object-fit: cover;
  background-size: cover;
}
.tips .img1 {
  height: 70%;
}
.tips .img2 {
  height: 90%;
}

.tips .textTips {
  text-justify: auto;
  padding: 0 5% 0 5%;
  color: rgb(98, 98, 98);
  font-size: 20px;
}

.tips .txt {
  color: rgb(98, 98, 98);
  font-size: 20px;
}

.link, .link:hover, .link:active {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.fa-brands {
  cursor: pointer;
}

.reviewsImg {
  width: 100%;
  margin-left: 1.5%;
}

.spinner-border {
  margin-left: 5px;
}

@media screen and (min-width: 990px) {
  .getCall {
    margin-top: 0%;
  }
}
@media screen and (max-width: 990px) {
  .getCall {
    margin-top: 20%;
  }
}
@media screen and (max-width: 1400px) {
  .getCall {
    margin-top: 7.5%;
  }
}

.search {
  border-radius: 20px;
}

#formSearch {
  margin-top: 5px;
  display: none;
}

#formSearch input {
  font-size: 13px;
}

.input-group-addon {
  border: none;
  padding-right: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
}

.input-group-addon .fa-solid {
  margin: 4px 2px 0 0;
  font-size: 15px;
  color: grey;
}

#icon, .fa-bars-staggered {
  margin-right: 10px;
  font-size: 15px;
  cursor: pointer;
}

.highlight {
  background-color: rgb(254, 239, 171) !important;
  color: black !important;
}

@media screen and (min-width: 1400px) {
  .localMovers, .blogs, .storageMain, .commercialMoverMain, .tips {
    height: 100vh; 
  }
}

.detailsBtn, .detailsBtn:hover, .detailsBtn:active, .detailsBtn:visited {
  font-style: none;
  float: right;
  color: white !important;
  padding: 5px 10px 6px;
  border: none !important;
  background: #4f42ba;
  background: transparent linear-gradient(271deg, #c46bd6 0%, #3927b1 72%, #3224afcc 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 14px;
}

.blogDetails { 
  display: inline-block;
  height: 100%;
  margin: 1% 0 1% 0;
  padding: 5px;
}

.blogDetails h1 { 
  margin: 10px;
  font-size: 35px;
  font-weight: bold;
}

.blogDetails img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 5px;
}

.blogDetails p { 
  font-size: 15px;
  margin: 1% 0 1% 0;
}
